.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Raleway:200,300,400,500,600,700&display=swap');

.services img{
  width: 100%;
  min-width: 670px;
}

.serviceDescription {
  position:relative;
  background-color:white;
  z-index: 1;
      left: 100px;
    width: 500px;
    height: 500px;
  vertical-align: middle;
}

.serviceDescription h3 {  
  font-size: 34px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: Raleway;
  margin-top: 100px;
  color: #543416; 
}


.services .two.column.row {
  padding-bottom: 100px!important;
}

.buttonBackground {
  position: absolute!important;  
  background-color: black;
  opacity: 0.3;
  z-index: 1!important;
  width: 350px;
  height: 47px;
  border-radius: 10px;
}


.ui.basic.button, .ui.basic.black.buttons .button {
    box-shadow:  0px 0px 0px 1px white inset !important;
    color: white !important;      
    border-radius:10px!important;
    font-weight: 400!important;
    font-family: Raleway!important;
    text-transform: uppercase!important;
    font-weight: 500!important;
    letter-spacing: 4px!important;
    z-index: 10!important;
    width: 352px;
    height: 47px;
    font-size: 18px;
    top: 150px!important;
}

.slick-dots {
  bottom:-20px;
}

.ui.centered.card {
  box-shadow:none;
  width:80%;
}

.ui.centered.card>.image>img {
  height:240px;
}

@media only screen and (max-width: 1000px) {
  .ui.centered.card>.image>img {
    height:150px;
  }
}

.ui.card>.content {
  font-family:'Raleway';
}

.ui.card>.content>.header {
    font-size: 1.5em!important;
    font-weight: 400!important;    
}

.thumbnailCover {
  width: 100%;
  height: 240px;
  z-index: 1;
  position:absolute;
  top:0px;
}

.thumbnailCoverLink {  
  height: 240px;
  opacity: 0.0;
  z-index: 0;
  color: white;  
  text-align:center;
  vertical-align: center;
  text-transform: uppercase;
  background-color: black;
  font-family: Raleway;
  font-weight: 700;
  position: absolute;
}


@media only screen and (max-width: 1000px) {
  .thumbnailCoverLink {
    height:150px;
  }

}

@media (min-width:0px)  { /* smartphones, Android phones, landscape iPhone */ 
  .desktopDescription {
    opacity: 0;
  }
  .mobileMenu {
    width: 60px;
    opacity: 1;
    z-index:10;
    right:0px;
    top:10px;
  }
  .desktopMenu{
    opacity: 0;
    z-index: -1;
    width: 0px;    
  }
  .buttonBackground{
    position:absolute!important;
    left: auto!important;
    right: auto!important; 
    top:0px;
    z-index:1!important;
    width: 320px;
  }
  .mainButton{
    z-index:10!important;
  }
 .ui.basic.button, .ui.basic.black.buttons .button {
    position: relative!important;
    z-index:10!important;
    top: 0px!important;
    width: 322px;
    font-size:14px;
  }
  .desktopSlider {
    z-index:-1;
    opacity:0;
    width:0px;
    top:-1000px;
    position:absolute;
  }
  .mobileSlider {
    z-index:1;
    opacity:1;
    width:auto;
    height:auto;
  }
  .contactForm{
    width:100%;
  }
}

.visible .menu .transition {
  margin-top:-10px;
}

@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
  .desktopDescription {
    opacity: 0;
  }
  .mobileMenu {
    width: 60px;
    opacity: 1;
    z-index:10;
  }
  .desktopMenu{
    opacity: 0;
    z-index: -1;
    width: 0px;    
  }
  .buttonBackground{
    position:absolute!important;
    left: auto!important;
    right: auto!important; 
    top:0px;
    z-index:1!important;
  }
  .mainButton{
    z-index:10!important;
  }
 .ui.basic.button, .ui.basic.black.buttons .button {
    position: relative!important;
    z-index:10!important;
    top: 0px!important;
  }
  .contactForm{
    width:100%;
  }
}

@media (min-width:650px) {
  .buttonBackground{
    right: 296px!important; 
    left: auto!important;
    top: 150px!important;
    position:absolute!important;
  }
  .mainButton{
    left: auto!important;    
    position: absolute!important;
    right:292px!important;
    top: 150px!important;
  } 
  .ui.basic.button, .ui.basic.black.buttons .button {
    position: absolute!important;
    z-index:10!important;
    top: 150px!important;
    right:292px!important;
  } 
}

.ui.dropdown .menu{
  margin-top:-8px!important;
  border:0px;
}

@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .desktopDescription {
    opacity: 1;
  }
  .mobileMenu {
    width: 0px;
    opacity: 0;
    z-index:-1;
  }
  .desktopMenu{
    opacity: 1;
    z-index: 10;
    width: auto;
  }
  .buttonBackground{
    right: 296px!important; 
    left: auto!important;
    top: 150px!important;
    position:absolute!important;
  }
  .mainButton{
    left: auto!important;    
    position: absolute!important;
    right:292px!important;
    top: 150px!important;
  } 
  .ui.basic.button, .ui.basic.black.buttons .button {
    position: absolute!important;
    z-index:10!important;
    top: 150px!important;
    right:292px!important;
  }
  .mobileSlider {
    z-index:-1;
    opacity:0;
    width:0px;
    height:0px;
  }
  .desktopSlider {
    z-index:1;
    opacity:1;
    width:auto;
    height:auto;
    top:auto;
    left:auto;    
    position:relative;
  }
  .contactForm{
    width:50%;
  }
  .thumbnailCoverText {
    font-size: 24px;
  }
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
  .desktopDescription {
    opacity: 1;
  }
  .mobileMenu {
    width: 0px;
    opacity: 0;
    z-index:-1;
  }
  .desktopMenu{
    opacity: 1;
    z-index: 10;
    width: auto;
  }
  .buttonBackground{
    right: 296px!important; 
    left: auto!important;
    top: 150px!important;
    position: absolute!important;
  }
  .mainButton{
    left: auto!important;    
    position: absolute!important;
    right: 292px!important;
    top: 150px!important; 
  }
  .ui.basic.button, .ui.basic.black.buttons .button {
    position: absolute!important;
    z-index:10!important;
    top: 150px!important;
    right:292px!important;
  }
  .mobileSlider {
    z-index:-1;
    opacity:0;
    width:0px;
    height:0px;
    top:-1000px;
    left:0px;
    position:absolute;
  }
  .desktopSlider {
    z-index:1;
    opacity:1;
    width:auto;
    height:auto;
    top:auto;
    left:auto;    
  }
  .contactForm{
    width:50%;
  }
}

@media (min-width:1700px) {
  .serviceDescription {
    left: 200px;
    top: 70px;
  }
  .businesscards {
    top: 150px;
  }
}


.thumbnailCover:hover .thumbnailCoverLink {
    opacity: 0.5;
    cursor: pointer;
    z-index: 1;
    -webkit-transition: opacity 1s; /* Safari prior 6.1 */
    transition: opacity 1s;
    width: 100%;
}

.thumbnailCoverText {
    color: white;
    opacity: 0;
    z-index: 3;
    position: absolute;
    font-family: Raleway;
    letter-spacing: 2px;
    font-weight: 400;
    text-align: center;
    font-size: 34px;
    text-transform: uppercase;
    width: 100%;
    margin-top: 100px;
}

.thumbnailCover:hover .thumbnailCoverText {
    opacity: 1;
    cursor: pointer;
    -webkit-transition: opacity 1s; /* Safari prior 6.1 */
    transition: opacity 1s;
}

.ui.card>.content>.meta {
    font-size: 1.2em;
    color: black;
    padding-top:10px;
}

.ui.card>.content>.meta>span {
    position:absolute;
    right:0px;
    color: #dabe93;
    font-weight: 400;
    font-size: 1.2em;
    font-family: verdana;
  }

.ui.card>.content>.description {
  font-size: 1.3em;
  color:black;
  padding-top:3px;
}

.services h3{
 
}

#mapSearch{
  width: 100%;
  height: 1100px;
  border: 0px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.teamDescription {
  width: 100%;
  margin-left: 80px;
  text-align: left;
  margin-top: 50px;
  color: #6b5a34;
}

.teamDescription h1{
  font-family: FuturaLTW01-LightOblique;
  font-weight: 800;
  font-size: 52px;
  width: 200px;     
}
.teamDescription h2{
  font-family: Raleway;
  font-weight: 800;
  font-size: 20px;
}

.teamDescription p{
  font-family: Raleway;
  font-weight: 800;  
  color: black;
  width: 300px;    
}

.headerstyles {      
    font-family: Raleway;
    font-weight: 400;
    font-size: 26px;
    color: #5c4817;
    width: 200px;
    text-align: center;
}

.sectionHead {
  text-align: center;
  width: 100%;
  font-family: Raleway;
  font-size: 32px;
  color: #5c4817;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.quoteStyles {      
  font-family: Raleway;
  font-weight: 400;
  font-size: 16px;
  width: 80%;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ui.secondary.pointing.menu {
  background-color:white;
  position: relative;
  z-index:2;
}

.randomcover {
  background-color:white;
  z-index:1;
  position: absolute;
  top:-50px;
  height:50px;
  width:100%;
}

.ui.secondary.pointing.menu {
    margin-left: 0;
    margin-right: 0;
    margin-bottom:2px;
    border-bottom: 0px solid rgba(34,36,38,.15);
    font-size: 16px;
}
.ui.secondary.pointing.menu .item{
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}

.ui.secondary.pointing.menu a.item:hover {
    color: #5c4817;
}

.ui.secondary.pointing.menu a.active.item:hover {
    color: #5c4817;
    border-color: #5c4817;
}

.ui.secondary.pointing.menu .active.item {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid rgba(34,36,38,.15);
    border-color: #917c4a;
    font-weight: 700;
    color: #5c4817;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
}
